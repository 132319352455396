<template>
  <main>
    <div v-if="userType == 'retailer'">
      <retailerNavigation />
      <v-main app>
        <div class="page-wrapper">
          <div class="query_container mb-4">
            <SearchBtnV2
              :placeholder="listOfProductsPagination.search || ' Click here'"
              :searchQuery="listOfProductsPagination.search"
              :maxWidth="'300px'"
              @search="Search($event)"
            ></SearchBtnV2>

            <SelectDropV2
              class="ml-6"
              :icon="'sort'"
              :TextField="'title'"
              :placeholder="
                listOfProductsPagination.category || 'All categories'
              "
              :value="listOfProductsPagination.category"
              :sortList="[
                { id: 'all', title: 'All categories' },
                ...filters.categories,
              ]"
              @sortbyobject="SortByObject($event, 'category')"
              :maxWidth="'210px'"
            />
            <SelectDropV2
              :icon="'sort'"
              class="ml-6"
              :TextField="'value'"
              :placeholder="listOfProductsPagination.tag || 'All Tags'"
              :value="listOfProductsPagination.tag"
              :sortList="[{ id: 'all', value: 'All tags' }, ...filters.tags]"
              @sortbyobject="SortByObject($event, 'tag')"
              :maxWidth="'210px'"
            />
            <SelectDropV2
              :icon="'sort'"
              class="ml-6"
              :TextField="'name'"
              :placeholder="listOfProductsPagination.country || 'ship from'"
              :sortList="[
                { id: 'all', name: 'ship from' },
                ...filters.countries,
              ]"
              @sortbyobject="SortByObject($event, 'country')"
              :maxWidth="'210px'"
            />
            <SelectDropV2
              :icon="'sort'"
              class="ml-6"
              :TextField="'name'"
              :placeholder="
                listOfProductsPagination.shipping_time || 'Shipping time'
              "
              :sortList="[
                { id: 'all', name: 'All shippingTime' },
                ...filters.shipping_times,
              ]"
              @sortbyobject="SortByObject($event, 'shipping_time')"
              :maxWidth="'210px'"
            />
            <div>
              <SelectDropV2
                :icon="'sort'"
                :sortList="['Suggested', 'Latest', 'Trending']"
                @sortby="SortByObject($event, 'sort')"
                class="ml-6"
                :maxWidth="'210px'"
              />
            </div>
          </div>

          <div
            class="container-fluid pa-0"
            v-if="!hasNoProduct && this.products.length"
          >
            <div class="products-list-wrapper">
              <div v-for="product in products" :key="product.id">
                <ProductCard :product="product" :freeShipping="freeShipping" />
              </div>
            </div>
            <v-row v-if="page_size > 1">
              <v-col cols="12" sm="12" md="12">
                <v-pagination
                  v-model="page"
                  :length="page_size"
                  :total-visible="7"
                  @input="next"
                  color="#008f4a"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-pagination>
              </v-col>
            </v-row>
          </div>
          <v-container v-else-if="hasNoProduct && !this.products.length">
            <span class="no-result">No products found!</span>
          </v-container>
          <div v-else-if="noConnectedShop">
            <div class="empty-space">
              <h2 class="_title">
                You have no connected shop, please connect to a shop first
              </h2>
              <img
                src="@/assets/img/vgang-pale-bg.png"
                alt="vgang"
                class="_bg"
              />
            </div>
          </div>
        </div>
      </v-main>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Components
import CustomInput from "@/components/CustomInput.vue";
import ProductCard from "@/components/ProductCard";
import SelectDropV2 from "@/components/bases/SelectDropV2.vue";
import SearchBtnV2 from "@/components/bases/SearchBtnV2.vue";

// Services
import RetailerProducts from "@/services/RetailerProducts";
import retailerNavigation from "@/components/navigation/retailer.vue";
export default {
  name: "ListOfProducts",
  components: {
    ProductCard,
    CustomInput,
    retailerNavigation,
    SearchBtnV2,
    SelectDropV2,
  },
  data() {
    return {
      products: [],
      categories: [],
      filters: [],
      popular_categories: [],
      //seller: "",
      sellerID: [],
      count: 24,
      page: Number,
      page_size: 0,
      totalProductCount: "",
      SearchButtonIsDisabled: true,
      currentSort: "newest",
      category: null,
      country: null,
      tag: null,
      shipping_time: null,
      hasNoProduct: false,
      noConnectedShop: false,
      userType: "retailer",
      showSortBy: false,
      freeShipping: false,
      sortBy: "Newest",
      sort_by_arrow: false,
      showBanner: true,
      close: true,
    };
  },

  mounted() {
    const retailer = JSON.parse(JSON.stringify(this.$store.state.retailer));
    if (
      retailer.listOfProductsPagination &&
      Object.keys(retailer.listOfProductsPagination).length === 0 &&
      Object.getPrototypeOf(retailer.listOfProductsPagination) ===
        Object.prototype
    ) {
      this.page = 1;
      this.$router
        .push({
          query: {
            category: this.listOfProductsPagination.category || "all",
            pagination: this.listOfProductsPagination.category || "all",
            ...(this.listOfProductsPagination.search && {
              q: this.listOfProductsPagination.search,
            }),
          },
        })
        .catch(() => {});
    } else {
      this.page = retailer.listOfProductsPagination.offset / this.count + 1;
      this.$router
        .push({
          query: {
            category: this.listOfProductsPagination.category || "all",
            ...(this.listOfProductsPagination.search && {
              q: this.listOfProductsPagination.search,
            }),
          },
        })
        .catch(() => {});
    }

    this.getProducts(this.listOfProductsPagination);
    this.getCategories();
    this.getFilters();
    this.SortByObject(event, type);
  },

  computed: {
    ...mapGetters({
      listOfProductsPagination: "retailer/listOfProductsPagination",
      listOfSellers: "retailer/listOfSellers",
    }),
  },
  methods: {
    ...mapActions([
      "retailer/setListOfProductsPagination",
      "retailer/getListOfSellers",
    ]),
    async getProducts(oldPag) {
      const filterByCategory =
        this.currentSort === ("newest" || "latest") ? null : this.currentSort;
      let pag = {
        count: this.count,
        offset: this.count * (this.page - 1),
        sort: "newest",
        category: filterByCategory,
        tag: "all",
        country: "all",
        shipping_time: "all",
      };
      if (
        oldPag &&
        Object.keys(oldPag).length === 0 &&
        Object.getPrototypeOf(oldPag) === Object.prototype
      ) {
        await this["retailer/setListOfProductsPagination"](pag);
      } else await this["retailer/setListOfProductsPagination"](oldPag);
      RetailerProducts.getProducts(this.listOfProductsPagination)
        .then((response) => {
          if (response.data) {
            this.products = response.data.products;
            this.freeShipping = response.data.freeShipping;
            this.totalProductCount = response.data.totalCount;
            this.page_size = Math.ceil(response.data.totalCount / this.count);
            if (this.products.length == 0) this.hasNoProduct = true;
            else this.hasNoProduct = false;
            window.scrollTo(0, 0);
          } else {
            this.hasNoProduct = true;
          }
          this.$router.push({
            query: {
              category: this.listOfProductsPagination.category || "all",
              pagination: this.listOfProductsPagination.category || "5",
              tag: this.listOfProductsPagination.tag || "all",
              country: this.listOfProductsPagination.country || "all",
              shipping_time:
                this.listOfProductsPagination.shipping_time || "all",
              ...(this.listOfProductsPagination.search && {
                q: this.listOfProductsPagination.search,
              }),
            },
          });
        })
        .catch((e) => {
          console.log(e.message);
          this.noConnectedShop = true;
        });
    },
    getCategories() {
      RetailerProducts.getCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getFilters() {
      RetailerProducts.getFilters()
        .then((response) => {
          this.filters = response.data;
          this.popular_categories = [...response.data.categories]
            .filter((c) => c.is_popular)
            .map((c) => c);
          console.log(
            [...response.data.categories]
              .filter((c) => !c.is_popular)
              .map((c) => c)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    popularCategory(categoryid) {
      var e = {
        sort_by_object: {
          id: categoryid,
        },
      };
      this.SortByObject(e, "category");
    },

    async SortBySellers(event) {
      this.seller = String(event.multiSelectedValue);
      this.Search();
    },
    async Search(event) {
      let pag = {
        count: this.count,
        offset: 0,
        search: event || null,
        category: this.listOfProductsPagination.category || "all",
        pagination: this.listOfProductsPagination.category,
      };
      await this["retailer/setListOfProductsPagination"](pag);
      RetailerProducts.getProducts(pag)
        .then((response) => {
          this.products = response.data.products;
          this.freeShipping = response.data.freeShipping;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);
          if (this.products.length == 0) this.hasNoProduct = true;
          else this.hasNoProduct = false;
          this.$router
            .push({
              query: {
                pagination: this.listOfProductsPagination.category || "5",
                category: this.listOfProductsPagination.category || "all",
                tag: this.listOfProductsPagination.tag || "all",
                country: this.listOfProductsPagination.country || "all",
                shipping_time:
                  this.listOfProductsPagination.shipping_time || "all",
              },
            })
            .catch(() => {});
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async SortByObject(event, type) {
      if (type === "category") {
        this.category =
          event.sort_by_object.id === "all" ? null : event.sort_by_object.id;
        this.page = 1;
      } else if (type === "shipping_time") {
        this.shipping_time = event.sort_by_object.id;
        this.page = 1;
      } else if (type === "tag") {
        this.tag = event.sort_by_object.id;
        this.page = 1;
      } else if (type === "country") {
        this.country = event.sort_by_object.id;
        this.page = 1;
      } else if (type === "sort") {
        this.sort = event.sort_by;
        this.page = 1;
      } else {
        return;
      }

      let pag = {
        count: this.count,
        offset: 0,
        sort: this.sort,
        category: this.category,
        country: this.country,
        shipping_time: this.shipping_time,
        tag: this.tag,
        seller: this.sellerID,
        search: this.listOfProductsPagination.search,
      };

      await this["retailer/setListOfProductsPagination"](pag);
      RetailerProducts.getProducts(this.listOfProductsPagination)
        .then((response) => {
          this.products = response.data.products;
          this.freeShipping = response.data.freeShipping;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);
          this.showSortBy = false;
          if (this.products.length == 0) this.hasNoProduct = true;
          else this.hasNoProduct = false;
          window.scrollTo(0, 0);
          this.$router
            .push({
              query: {
                pagination: this.listOfProductsPagination.category,
                category: this.listOfProductsPagination.category || "all",
                tag: this.listOfProductsPagination.tag || "all",
                country: this.listOfProductsPagination.country || "all",
                shipping_time:
                  this.listOfProductsPagination.shipping_time || "all",
                ...(this.listOfProductsPagination.search && {
                  q: this.listOfProductsPagination.search,
                }),
              },
            })
            .catch(() => {});
        })
        .catch((e) => {
          console.log(e);
        });
    },
    next(page) {
      const newPag = JSON.parse(JSON.stringify(this.listOfProductsPagination));
      newPag.offset = this.count * (page - 1);
      this["retailer/setListOfProductsPagination"](newPag);

      this.getProducts(newPag);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.no-result {
  color: green;
  padding-top: 250px;
  padding-bottom: 250px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.products-list-wrapper {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  grid-gap: 24px;
  justify-content: start;
  align-items: stretch;
}

.fake_select_wrapper {
  width: 257px !important;
}
.list_banner {
  width: 100%;
  margin-top: 16px;
  margin-left: 84px;
  padding-right: 100px;
  &.isOpen {
    padding-left: 75px;
  }
}
.advance_search {
  display: flex;
  grid-template-columns: repeat(auto-fill, 312px);

  justify-content: start;
  align-items: stretch;
}
.categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 16px;

  height: 68px;
  background: #f2f4f7;
  border-radius: 5px;
  margin-bottom: 16px;
}
.popular_categories {
  display: flex;
  height: 24px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $grey-900;
  align-items: center;
  width: 800px;
}
.all_categories {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 12px;
  gap: 6px;
  padding-left: 45px;
  width: 1500px;
}
.cate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 6px;

  height: 44px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white;
  background: $grey-700;
  border-radius: 4px;
  flex-basis: fit-content;
}
.sortIcon {
  position: absolute;
  width: 15px;
  margin-top: 16px;
}
.importlist_banner {
  margin-top: 16px;
  margin-left: 84px;
  padding-right: 100px;
  width: 100%;
}
</style>
