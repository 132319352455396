var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.userType == 'retailer')?_c('div',[_c('retailerNavigation'),_c('v-main',{attrs:{"app":""}},[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"query_container mb-4"},[_c('SearchBtnV2',{attrs:{"placeholder":_vm.listOfProductsPagination.search || ' Click here',"searchQuery":_vm.listOfProductsPagination.search,"maxWidth":'300px'},on:{"search":function($event){return _vm.Search($event)}}}),_c('SelectDropV2',{staticClass:"ml-6",attrs:{"icon":'sort',"TextField":'title',"placeholder":_vm.listOfProductsPagination.category || 'All categories',"value":_vm.listOfProductsPagination.category,"sortList":[
              { id: 'all', title: 'All categories' },
              ..._vm.filters.categories,
            ],"maxWidth":'210px'},on:{"sortbyobject":function($event){return _vm.SortByObject($event, 'category')}}}),_c('SelectDropV2',{staticClass:"ml-6",attrs:{"icon":'sort',"TextField":'value',"placeholder":_vm.listOfProductsPagination.tag || 'All Tags',"value":_vm.listOfProductsPagination.tag,"sortList":[{ id: 'all', value: 'All tags' }, ..._vm.filters.tags],"maxWidth":'210px'},on:{"sortbyobject":function($event){return _vm.SortByObject($event, 'tag')}}}),_c('SelectDropV2',{staticClass:"ml-6",attrs:{"icon":'sort',"TextField":'name',"placeholder":_vm.listOfProductsPagination.country || 'ship from',"sortList":[
              { id: 'all', name: 'ship from' },
              ..._vm.filters.countries,
            ],"maxWidth":'210px'},on:{"sortbyobject":function($event){return _vm.SortByObject($event, 'country')}}}),_c('SelectDropV2',{staticClass:"ml-6",attrs:{"icon":'sort',"TextField":'name',"placeholder":_vm.listOfProductsPagination.shipping_time || 'Shipping time',"sortList":[
              { id: 'all', name: 'All shippingTime' },
              ..._vm.filters.shipping_times,
            ],"maxWidth":'210px'},on:{"sortbyobject":function($event){return _vm.SortByObject($event, 'shipping_time')}}}),_c('div',[_c('SelectDropV2',{staticClass:"ml-6",attrs:{"icon":'sort',"sortList":['Suggested', 'Latest', 'Trending'],"maxWidth":'210px'},on:{"sortby":function($event){return _vm.SortByObject($event, 'sort')}}})],1)],1),(!_vm.hasNoProduct && this.products.length)?_c('div',{staticClass:"container-fluid pa-0"},[_c('div',{staticClass:"products-list-wrapper"},_vm._l((_vm.products),function(product){return _c('div',{key:product.id},[_c('ProductCard',{attrs:{"product":product,"freeShipping":_vm.freeShipping}})],1)}),0),(_vm.page_size > 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":_vm.page_size,"total-visible":7,"color":"#008f4a","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":_vm.next},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1):(_vm.hasNoProduct && !this.products.length)?_c('v-container',[_c('span',{staticClass:"no-result"},[_vm._v("No products found!")])]):(_vm.noConnectedShop)?_c('div',[_c('div',{staticClass:"empty-space"},[_c('h2',{staticClass:"_title"},[_vm._v(" You have no connected shop, please connect to a shop first ")]),_c('img',{staticClass:"_bg",attrs:{"src":require("@/assets/img/vgang-pale-bg.png"),"alt":"vgang"}})])]):_vm._e()],1)])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }